import React from 'react';

const About = () => {
    return (
        <section className="section-about">
            <div className="row" data-aos="fade-up">
                <div className="about">
                    <div className="about__photo">
                        <figure className="about__shape">
                            <img className="about__img" src="../img/avataaars.png" alt="Profile" />
                        </figure>
                    </div>
                    <div className="about__text">
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            Hi! My Name is John
                        </h3>
                        <p>
                            I am a full stack developer based in London with 2+ years of experience.
                            I really enjoy working on heavily client-focused projects and exciting new technologies.
                            I'm also a big football and tennis fan, and do several martial arts.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;