import React from 'react';
import iconSet from '../../selection.json';
import IcomoonReact from 'icomoon-react';

const Card = () => {
  return (
    <div class="card" data-aos="fade-right">
      <div class="card__side card__side--front">
        <img
          className="card__picture card__picture"
          src="../img/banking.jpg"
          alt="dolla"
        />
        <h4 class="card__heading">
          <span class="card__heading-span card__heading-span--1">Dolla</span>
        </h4>
        <div class="card__details">
          <ul>
            <li>React</li>
            <li>Styled Components</li>
            <li>React Scroll</li>
            <li>CSS</li>
          </ul>
        </div>
      </div>
      <div class="card__side card__side--back card__side--back-1">
        <div class="card__cta">
          <div class="card__price-box">
            <p class="card__price-only">View Project</p>
            <p class="card__back-description">
              Dolla is a mock up virtual banking website created to show a React
              website using Styled Components.
            </p>
          </div>
          <a
            href="https://github.com/yiannisfaf/React-StyledComponents-SmoothScroll"
            rel="noopener noreferrer"
            target="_blank"
            class="btn btn--white btn--small"
          >
            <div className="btn__icon__container">
              <IcomoonReact
                iconSet={iconSet}
                className="btn__icon"
                icon="github"
              />
            </div>
            View on Github
          </a>
          <a
            href="https://immense-gorge-22061.herokuapp.com/"
            rel="noopener noreferrer"
            target="_blank"
            class="btn btn--white btn--small"
          >
            <div className="btn__icon__container">
              <IcomoonReact
                iconSet={iconSet}
                className="btn__icon"
                icon="earth"
              />
            </div>
            View Live Site
          </a>
        </div>
      </div>
    </div>
  );
};

export default Card;
