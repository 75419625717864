import React from 'react';
import iconSet from '../../selection.json';
import IcomoonReact from 'icomoon-react';

const Card = () => {
  return (
    <div class="card" data-aos="fade-right">
      <div class="card__side card__side--front">
        <img
          className="card__picture card__picture"
          src="../img/jbook.jpg"
          alt="jbook"
        />
        <h4 class="card__heading">
          <span class="card__heading-span card__heading-span--1">JBook</span>
        </h4>
        <div class="card__details">
          <ul>
            <li>React</li>
            <li>Typescript</li>
            <li>Redux</li>
            <li>ESLint</li>
            <li>CSS</li>
          </ul>
        </div>
      </div>
      <div class="card__side card__side--back card__side--back-1">
        <div class="card__cta">
          <div class="card__price-box">
            <p class="card__price-only">View Project</p>
            <p class="card__back-description">
              Jbook is command line app built for testing React and Javascript
              code in the browser. The app can save developers loads of time as
              they can test out and run code quickly and simply.
            </p>
          </div>
          <a
            href="https://github.com/yiannisfaf/jbook"
            rel="noopener noreferrer"
            target="_blank"
            class="btn btn--white btn--small"
          >
            <div className="btn__icon__container">
              <IcomoonReact
                iconSet={iconSet}
                className="btn__icon"
                icon="github"
              />
            </div>
            View on Github
          </a>
        </div>
      </div>
    </div>
  );
};

export default Card;
