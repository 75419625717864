import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';

const Navbar = () => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.pageYOffset >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
  }, []);

  return (
    console.log(scrollNav),
    (
      <header className="header">
        <nav
          class="header__nav-box"
          style={{
            backgroundColor: scrollNav ? '#192841' : 'transparent',
            zIndex: scrollNav ? 10 : 1,
          }}
        >
          <ul class="header__list">
            <li class="header__item">
              <Link
                className="header__link"
                activeClass="header__link__active"
                to="section-about"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                About
              </Link>
            </li>
            <li class="header__item">
              <Link
                className="header__link"
                activeClass="header__link__active"
                to="section-tech"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Technologies
              </Link>
            </li>
            <li class="header__item">
              <Link
                className="header__link"
                activeClass="header__link__active"
                to="section-projects"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Projects
              </Link>
            </li>
          </ul>
        </nav>

        <div className="header__text-box">
          <h1 className="heading-primary">
            <span className="heading-primary--main">John Fafalios</span>
            <span className="heading-primary--sub">Full Stack Developer</span>
          </h1>
        </div>
      </header>
    )
  );
};

export default Navbar;
