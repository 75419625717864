import React from 'react';

import Card from './Card';
import Jbook from './Jbook';
import Emaily from './Emaily';
import Youtube from './Youtube';

const Projects = () => {
    return (
        <section className="section-projects">
            <div className="u-center-text u-margin-bottom-big">
                <h2 className="heading-secondary--white">
                    Projects
                </h2>
            </div>

            <div class="row">
                <div class="col-1-of-3">
                    <Card />
                </div>
                <div class="col-1-of-3">
                    <Jbook />
                </div>
                <div class="col-1-of-3">
                    <Emaily />
                </div>
            </div>
            <div class="row">
                <div class="col-1-of-3">
                    <Youtube />
                </div>
            </div>
            <div className="u-margin-bottom-big" />
        </section>
    );
};

export default Projects;