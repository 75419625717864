import React from 'react';
import iconSet from '../../selection.json';
import IcomoonReact from 'icomoon-react';

const Card = () => {
  return (
    <div class="card" data-aos="fade-right">
      <div class="card__side card__side--front">
        <img
          className="card__picture card__picture"
          src="../img/emaily.jpg"
          alt="emaily"
        />
        <h4 class="card__heading">
          <span class="card__heading-span card__heading-span--1">Emaily</span>
        </h4>
        <div class="card__details">
          <ul>
            <li>React</li>
            <li>Redux</li>
            <li>Nodejs</li>
            <li>MongoDb</li>
            <li>Tailwind CSS</li>
          </ul>
        </div>
      </div>
      <div class="card__side card__side--back card__side--back-1">
        <div class="card__cta">
          <div class="card__price-box">
            <p class="card__price-only">View Project</p>
            <p class="card__back-description">
              Emaily is an app that allows you to send out surveys to multiple
              people at once. Simply pay for tokens through the Stripe payment
              system (using dummy stripe details) to send out surveys.
            </p>
          </div>
          <a
            href="https://github.com/yiannisfaf/Emaily"
            rel="noopener noreferrer"
            target="_blank"
            class="btn btn--white btn--small"
          >
            <div className="btn__icon__container">
              <IcomoonReact
                iconSet={iconSet}
                className="btn__icon"
                icon="github"
              />
            </div>
            View on Github
          </a>
          <a
            href="https://emaily-sg-fullstack.herokuapp.com/"
            rel="noopener noreferrer"
            target="_blank"
            class="btn btn--white btn--small"
          >
            <div className="btn__icon__container">
              <IcomoonReact
                iconSet={iconSet}
                className="btn__icon"
                icon="earth"
              />
            </div>
            View Live Site
          </a>
        </div>
      </div>
    </div>
  );
};

export default Card;
