import React from 'react';
import iconSet from '../selection.json';
import IcomoonReact from 'icomoon-react';

const Footer = () => {
  return (
    <footer class="footer">
      <div class="row">
        <div class="col-1-of-2">
          <div class="footer__navigation">
            <ul class="footer__list">
              <li class="footer__item">
                <a
                  href="https://github.com/yiannisfaf"
                  rel="noopener noreferrer"
                  target="_blank"
                  class="footer__link"
                >
                  <IcomoonReact
                    iconSet={iconSet}
                    className="footer__github"
                    icon="github"
                  />
                </a>
              </li>
              <li class="footer__item">
                <a
                  href="https://github.com/yiannisfaf"
                  rel="noopener noreferrer"
                  target="_blank"
                  class="footer__link"
                >
                  <IcomoonReact
                    iconSet={iconSet}
                    className="footer__mail"
                    icon="envelop"
                  />
                </a>
              </li>
              <li class="footer__item">
                <a
                  href="https://www.linkedin.com/in/john-fafalios-52b768175"
                  rel="noopener noreferrer"
                  target="_blank"
                  class="footer__link"
                >
                  <IcomoonReact
                    iconSet={iconSet}
                    className="footer__linkedin"
                    icon="linkedin2"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-1-of-2">
          <p class="footer__copyright">Design inspired by Jonas Schmedtmann.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
