import React from 'react';

import TechGrid from './Tech-Grid';

const Tech = () => {
    return (
        <section className="section-tech">
            <div className="u-center-text u-margin-bottom-big">
                <h2 className="heading-secondary">
                    Technologies I...
                </h2>
            </div>
            
            <TechGrid />
            <div className="u-margin-bottom-big" />
        </section>
    )
};

export default Tech;