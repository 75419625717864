import React, { useEffect } from 'react';
import AOS from 'aos';

import 
{ 
    known,
    familiar,
    exploring
} from './data';

const TechGrid = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000
        });
        AOS.refresh();
    }, []);

    const renderKnown = () => {
        return known.map((data) => {
            return (
                <div className="tech" data-aos="fade-right">
                    <figure className="tech__shape">
                        <img className="tech__img" src={data.image} alt="React" />
                    </figure>
                    <span class="tech__label">{data.name}</span>
                </div>
            );
        })
    };

    const renderFamiliar = () => {
        return familiar.map((data) => {
            return (
                <div className="tech" data-aos="fade-right">
                    <figure className="tech__shape">
                        <img className="tech__img" src={data.image} alt="React" />
                    </figure>
                    <span class="tech__label">{data.name}</span>
                </div>
            );
        })
    };

    const renderExploring = () => {
        return exploring.map((data) => {
            return (
                <div className="tech" data-aos="fade-right">
                    <figure className="tech__shape">
                        <img className="tech__img" src={data.image} alt="React" />
                    </figure>
                    <span class="tech__label">{data.name}</span>
                </div>
            );
        })
    };

    return (
        <div>
            <div className="row" data-aos="fade-up">
                <h3 class="heading-tertiary u-line-bottom">
                    Know
                </h3>
                {renderKnown()}
            </div>
            <div className="row" data-aos="fade-up">
                <h3 class="heading-tertiary u-line-bottom">
                    Am Familiar With
                </h3>

                {renderFamiliar()}
            </div>
            <div className="row" data-aos="fade-up">
                <h3 class="heading-tertiary u-line-bottom">
                    Am Exploring
                </h3>

                {renderExploring()}
            </div>
        </div>
    )
};

export default TechGrid;