
export const known = [
    {
        name: "React",
        image: "../img/react_logo.png"
    },
    {
        name: "Redux",
        image: "../img/redux-logo.png"
    },
    {
        name: "JavaScript",
        image: "../img/js-logo.png"
    },
    {
        name: "TypeScript",
        image: "../img/ts-logo.png"
    },
    {
        name: "NodeJS",
        image: "../img/nodejs_logo.png"
    },
    {
        name: "Python",
        image: "../img/pythonlogo.png"
    },
    {
        name: "CSS3",
        image: "../img/css-logo.png"
    },
    {
        name: "HTML5",
        image: "../img/html5-logo.png"
    }
];

export const familiar = [
    {
        name: "PostgresSQL",
        image: "../img/postgres-logo.png"
    },
    {
        name: "MongoDB",
        image: "../img/mongodb-logo.png"
    },
    {
        name: "Passport",
        image: "../img/passport-logo.png"
    },
];


export const exploring = [
    {
        name: "GraphQL",
        image: "../img/graphql-logo.png"
    },
    {
        name: "TailwindCSS",
        image: "../img/tailwind-logo.png"
    },
    {
        name: "NextJS",
        image: "../img/next-logo.png"
    },
    {
        name: "Svelte",
        image: "../img/svelte-logo.png"
    },
];

